import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

// Styles
import './Button.scss';

const Button = ({ color, size, className, ...props }) => {
  // Dynamic Tag
  let Tag = props.to ? Link : props.href ? 'a' : 'button';

  // Dynamic Classes
  const classes = ClassNames(className, 'button', {
    [`button--${size}`]: true,
    [`button--${color}`]: true
  });

  // Disabled
  if (props.tabIndex === -1) Tag = 'span';

  // DOM
  return (
    <Tag className={classes} {...props}>
      {props.children}
    </Tag>
  );
};

Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  tabIndex: PropTypes.string
};

Button.defaultProps = {
  size: 'm',
  color: 'orange'
};

export default React.memo(Button);
