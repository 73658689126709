import React, { useCallback, useEffect } from 'react';
import ClassNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useImmer } from 'use-immer';

// Dependencies
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

// React Components
import Button from 'components/Button';
import Container from 'components/Container';

// Styles
import './SiteHeader.scss';

const SiteHeader = () => {
  // Local State
  const [status, setStatus] = useImmer({ open: false });

  // Dynamic Classes
  const classes = ClassNames('site-header', { 'site-header--open': status.open });

  // Handlers
  const handleToggle = useCallback(
    () => {
      setStatus(draft => {
        draft.open = !draft.open;
      });
    },
    [setStatus]
  );

  // Side-Effects
  useEffect(
    () => {
      status.open
        ? disablePageScroll(document.body)
        : enablePageScroll(document.body);
    },
    [status.open]
  );

  // DOM
  return (
    <header className={classes}>
      <Container>
        <NavLink
          to="/"
          activeClassName="is-active"
          className="site-header__logo"
        >
          <span className="u-visually-hidden">
            MSI - Age of Invention
          </span>
        </NavLink>

        <button
          type="button"
          onClick={handleToggle}
          className="site-header__hamburger button"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <nav className="site-header__menu">
          <ul className="site-header__items">
            <li>
              <NavLink
                to="/program"
                activeClassName="is-active"
                className="site-header__link"
              >
                Program
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faq"
                activeClassName="is-active"
                className="site-header__link"
              >
                Help and FAQ
              </NavLink>
            </li>
          </ul>

          <ul className="site-header__items">
            <li>
              <NavLink
                to="/sponsors"
                activeClassName="is-active"
                className="site-header__link"
              >
                Our Sponsors
              </NavLink>
            </li>
            <li>
              <Button
                size="s"
                target="_blank"
                color="orange-lighter"
                rel="noopener noreferrer"
                className="site-header__button"
                href="https://www.msichicago.org/support/donate/more-ways-to-give/columbian-ball/donate"
              >
                Donate
              </Button>
            </li>
          </ul>
        </nav>
      </Container>
    </header>
  );
};

export default React.memo(SiteHeader);
