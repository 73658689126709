import React from 'react';

// React Components
import Icon from 'components/Icon';
import Container from 'components/Container';

// Images
import Logo from 'assets/images/footer/logo-footer@2x.png';

// Styles
import './SiteFooter.scss';

const SiteFooter = () => {
  return (
    <footer className="site-footer">
      <Container>
        <div className="site-footer__top">
          <div className="site-footer__column">
            <a href="https://www.msichicago.org/" target="_blank" rel="noopener noreferrer">
              <img
                src={Logo}
                className="site-footer__logo"
                alt='Museum of science + industry - Chicago'
              />
            </a>
          </div>

          <div className="site-footer__column">
            <nav className="site-footer__menu">
              <h3 className="site-footer__title">Contact us</h3>
              <ul className="site-footer__items">
                <li><a href="mailto:columbianball@msichicago.org" target="_blank" rel="noopener noreferrer">columbianball@msichicago.org</a></li>
                <li><a href="tel:+17739476146" target="_blank" rel="noopener noreferrer">(773) 947-6146</a></li>
              </ul>
            </nav>
          </div>

          <div className="site-footer__column">
            <nav className="site-footer__menu">
              <h3 className="site-footer__title">Discover more</h3>
              <ul className="site-footer__items">
                <li><a href="https://www.msichicago.org/support/donate/more-ways-to-give/columbian-ball/donate" target="_blank" rel="noopener noreferrer">Donate Now</a></li>
                <li><a href="https://www.msichicago.org/support/donate/more-ways-to-give/columbian-ball/auction" target="_blank" rel="noopener noreferrer">Bid in the Auction</a></li>
              </ul>
            </nav>
          </div>

          <div className="site-footer__column">
            <nav className="site-footer__menu">
              <h3 className="site-footer__title">Follow us</h3>
              <ul className="site-footer__socials">
                <li>
                  <a href="https://www.facebook.com/msichicago" target="_blank" rel="noopener noreferrer">
                    <Icon
                      width={56}
                      height={56}
                      name="facebook"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/msichicago" target="_blank" rel="noopener noreferrer">
                    <Icon
                      width={56}
                      height={56}
                      name="twitter"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/msichicago/" target="_blank" rel="noopener noreferrer">
                    <Icon
                      width={56}
                      height={56}
                      name="instagram"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/msichicago" target="_blank" rel="noopener noreferrer">
                    <Icon
                      width={56}
                      height={56}
                      name="youtube"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="site-footer__bottom">
          <ul className="site-footer__submenu">
            <li><a href="https://www.msichicago.org/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
            <li><a href="https://www.msichicago.org/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
          </ul>

          <p>© 2020 Museum of Science and Industry, Chicago</p>
        </div>
      </Container>
    </footer>
  );
};

export default React.memo(SiteFooter);
